import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Img from "gatsby-image"
import { FaMobile, FaChalkboardTeacher, FaGoogle } from "react-icons/fa"

const OurServiceWebsitePage = ({ data }) => {
  const featuredItems = [
    {
      id: 1,
      class: "col-10 col-sm-4 mx-auto featured-card -green",
      icon: <FaMobile />,
      title: "Web Responsive",
      description:
        "เว็บไซต์ที่เราสร้างนั้นรองรับการแสดงผลได้ทั้งในมือถือ แท็บเล็ต และคอมพิวเตอร์ สวยในทุกรูปแบบการแสดงผล",
      link: "/our-services/website",
    },
    {
      id: 2,
      class: "col-10 col-sm-4 mx-auto featured-card -green",
      icon: <FaGoogle />,
      title: "SEO",
      description:
        "เว็บไซต์ของคุณจะได้ 1 Keyword ในการติดหน้าแรก Google โดยที่คีย์เวิร์ดนั้นจะเป็นคีย์เวิร์ดที่เหมาะสมกับเว็บไซต์คุณ",
      link: "/our-services/fix-facebook-page-advertising-account-closed",
    },
    {
      id: 3,
      class: "col-10 col-sm-4 mx-auto featured-card -green",
      icon: <FaChalkboardTeacher />,
      title: "Customize",
      description:
        "การจัดการหลังบ้านใช้งานง่าย สามารถปรับปรุงข้อมูลได้อย่างง่ายดาย พร้อมคู่มือการใช้งานที่ดูแล้วเข้าใจทันที",
      link: "/our-services/fix-facebook-page-vision-disabled",
    },
  ]

  return (
    <Layout>
      <SEO
        title="ทูสกายซอฟต์ รับทำเว็บไซต์ และแก้ปัญหาเพจ Facebook"
        description="รับทำเว็บไซต์ทุกประเภท เว็บส่วนตัว เว็บขายของ ระบบภายในตามสั่ง ระบบตามสั่งออนไลน์ และบริการแก้ปัญหาเพจ Facebook โดนปิดบัญชีโฆษณา เพจถูกปิดการมองเห็น โปรโมทโพสต์ไม่ได้"
      />
      <div className="container">
        <Img fluid={data.cover.childImageSharp.fluid} />
        <section>
          <div className="row">
            {featuredItems.map(item => {
              return (
                <div key={item.id.toString()} className={item.class}>
                  <div className="featured-card__wrap">
                    <div className="featured-card__line"></div>
                    <div className="featured-card__icon">{item.icon}</div>
                    <div className="featured-card__title">{item.title}</div>
                    <div className="featured-card__description">
                      {item.description}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </section>

        <section>
          <Img fluid={data.feature.childImageSharp.fluid} />
          <div className="space-30px"></div>
        </section>

        <section>
          <h2>รูปแบบเว็บไซต์และราคา</h2>

          <div className="space-30px"></div>

          <div className="row">
            <div className="col-6">
              <div
                className="img-thumbnail-scroll"
                style={{
                  backgroundImage: `url(${data.websiteStyle001.childImageSharp.fluid.src})`,
                }}
              ></div>
            </div>
            <div className="col-6">
              <h3>รูปแบบที่ 1 โปรโมทสินค้าแบบมีสไตล์</h3>
              <p>
                เน้นโปรโมทสินค้า ดูเด่น และปรับแต่งได้หลากหลาย
                รองรับการติดหน้าแรก Google
              </p>
              <p>ราคา 22,000 บาท</p>
            </div>
          </div>

          <div className="space-30px"></div>

          <div className="row">
            <div className="col-6">
              <div
                className="img-thumbnail-scroll"
                style={{
                  backgroundImage: `url(${data.websiteStyle002.childImageSharp.fluid.src})`,
                }}
              ></div>
            </div>
            <div className="col-6">
              <h3>รูปแบบที่ 2 ประชาสัมพันธ์ธุรกิจ</h3>
              <p>
                เน้นประชาสัมพันธ์ธุรกิจ และปรับแต่งได้หลากหลาย
                รองรับการติดหน้าแรก Google
              </p>
              <p>ราคา 28,000 บาท (ไม่รวมค่าโดเมนและโฮสต์)</p>
            </div>
          </div>

          <div className="space-30px"></div>

          <div className="row">
            <div className="col-6">
              <div
                className="img-thumbnail-scroll"
                style={{
                  backgroundImage: `url(${data.websiteStyle003.childImageSharp.fluid.src})`,
                }}
              ></div>
            </div>
            <div className="col-6">
              <h3>รูปแบบที่ 3 ระบบ E-Commerce</h3>
              <p>
                ขายสินค้าด้วยระบบตะกร้าสินค้า และปรับแต่งได้หลากหลาย
                รองรับการติดหน้าแรก Google
              </p>
              <p>ราคา 40,000 บาท (ไม่รวมค่าโดเมนและโฮสต์)</p>
            </div>
          </div>

          <div className="space-30px"></div>

          <div className="row">
            <div className="col-6">
              <div
                className="img-thumbnail-scroll"
                style={{
                  backgroundImage: `url(${data.websiteStyle004.childImageSharp.fluid.src})`,
                }}
              ></div>
            </div>
            <div className="col-6">
              <h3>รูปแบบที่ 4 เว็บเล็กกระทัดรัด</h3>
              <p>
                ง่ายๆ เข้าถึงไว อยู่บนบริการ Google My Business
                ระบบหลังบ้านมีให้เลือกปรับแต่งได้น้อย
              </p>
              <p>ราคา 5,000 บาท (ไม่รวมค่าโดเมน)</p>
            </div>
          </div>
        </section>

        <div className="space-60px"></div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    cover: file(
      relativePath: { eq: "our-services/our-service-website-cover.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    feature: file(relativePath: { eq: "our-services/website-001.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    websiteStyle001: file(
      relativePath: { eq: "our-services/website-style-001.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    websiteStyle002: file(
      relativePath: { eq: "our-services/website-style-002.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    websiteStyle003: file(
      relativePath: { eq: "our-services/website-style-003.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    websiteStyle004: file(
      relativePath: { eq: "our-services/website-style-004.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default OurServiceWebsitePage
